<template>
  <div class="checkout-wrapper" v-loading.fullscreen="fullscreenLoading">
    <div class="payment-wrapper margin-top-78">
      <div class="payment-content order-box">
        <div class="title">{{$t('message.checkout.paymentMethod')}}</div>
        <div class="payment-wechat-wrapper">
          <el-row :gutter="80">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="qrcode-box">
                <div class="qrcode-wrapper">
                  <img src="../../assets/images/wechat/wechat.png" width="166" />
                  <div class="qrcode-img margin-top-13">
                    <img :src="order && order.qrCode" width="180" />
                  </div>
                  <div class="payment-tip margin-top-30">{{$t('message.checkout.wechatPaymentTip')}}</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="wechat-guide-box">
                <img class="example" src="../../assets/images/wechat/paymentGuide.png" width="256" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="product-content order-box margin-left-25">
        <!-- 订单摘要 -->
        <div class="title">{{$t('message.checkout.summary')}}</div>
        <div class="padding-left-20 padding-right-20 padding-bottom-24 padding-top-16">
          <div class="summary-item">
            <label>{{$t('message.checkout.orderno')}}:</label>
            <div class="content">{{ order && order.merchantTxnId }}</div>
          </div>
          <div class="summary-item">
            <label>{{$t('message.checkout.website')}}:</label>
            <div class="content">{{ order && order.website }}</div>
          </div>
          <div class="summary-item">
            <label>{{$t('message.checkout.amount')}}:</label>
            <div class="content amount">{{ order && order.txnCurrency }} {{ order && order.txnAmount }}</div>
          </div>
          <div class="btns margin-top-23">
            <el-button @click="submit" type="primary" class="btn-submit">{{$t('message.checkout.paymentCompleted')}}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStatus, getBrandInfo } from '@/api/checkout';
import { getWxMsg } from '@/api/wx';
import { getCheckoutType } from '@/utils/tools/ga';
import { exceptionShow } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      order: null,
      tryCount: 0,
      tryInterval: 15 * 1000,
      pending: 4,
      type: '',
    };
  },
  created() {
    this.type = getCheckoutType(this.$route.query.type, this.$route.query.key);
    getBrandInfo(this.$route.query.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
    getWxMsg(this.$route.query.key ? this.$route.query.key : this.$route.params.id).then((res) => {
      if (res.respCode === '20000') {
        this.order = res.data;
      }
      this.fullscreenLoading = false;
    }).catch(() => {
      this.fullscreenLoading = false;
    });
    const timer = setTimeout(() => {
      this.getOrderStatus(true);
      clearTimeout(timer);
    }, 15 * 1000);
  },
  methods: {
    getOrderStatus(interval = false) {
      this.tryCount += 1;
      getStatus(this.$route.query.key ? this.$route.query.key : this.$route.params.id).then(res => {
        if (res.respCode === '20000') {
          if (res.data.status === 'S') { // 成功
            this.$router.push({
              path: '/result/success',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          } else if (res.data.status === 'F') { // 失败
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          } else if (res.data.status === 'P' || res.data.status === 'R') { // 等待
            if (interval && this.tryCount < this.pending) { // 重试，若15次轮询后还没有结果，不再轮询，等待用户手动刷新
              const timer = setTimeout(() => {
                this.getOrderStatus(true);
                clearTimeout(timer);
              }, this.tryInterval);
            }
            // 如果是用户点击的已完成支付，订单还在P状态，则跳转到pending页面
            if (!interval) {
              this.$router.push({
                path: '/result/pending',
                query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
              });
            }
          } else {
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          }
        } else {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, this.type)) {
            return;
          }
          this.$router.push({
            path: '/result/failed',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
          });
        }
        this.fullscreenLoading = false;
      }).catch(() => {
        if (interval && this.tryCount < this.pending) { // 重试
          setTimeout(() => {
            this.getOrderStatus(true);
          }, this.tryInterval);
        }
        this.fullscreenLoading = false;
      });
    },
    submit() {
      this.fullscreenLoading = true;
      this.getOrderStatus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element-variables.scss';
@import '@/styles/checkout.scss';

.payment-wechat-wrapper {
  padding-top: 75px;
  padding-bottom: 145px;
  overflow: hidden;
  text-align: center;
  position: relative;
  .payment-tip {
    max-width: 195px;
    font-size: 16px;
    font-family: inherit;
    font-weight: 400;
    color: $color-title;
    line-height: 22px;
  }
  .qrcode-box {
    display: flex;
    justify-content: flex-end;
    height: 403px;
    .qrcode-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .qrcode-img {
        width: 180px;
        height: 180px;
        border: 1px solid $color-summary-label;
        border-radius: 4px;
        img {
          border-radius: 4px;
        }
      }
    }
  }
  .wechat-guide-box {
    text-align: left;
  }
  .example {
    max-width: 100%;
  }
}
.btn-submit {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .payment-wechat-wrapper {
    .qrcode-box {
      justify-content: center;
      height: inherit;
    }
    .wechat-guide-box {
      text-align: center;
      margin-top: 30px;
    }
  }
}
</style>
